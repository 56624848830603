import { Button } from 'antd';
import { NextPage } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';

import astroKemi from '../../public/kemi-astro.svg';
import space from '../../public/space.jpeg';
import { ROUTE } from '../router';

const PageNotFoundPage: NextPage = () => {
  const router = useRouter();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
        src={space}
        layout={'fill'}
        objectFit={'cover'}
        alt={'background'}
      />
      <Image src={astroKemi} width={200} height={200} alt={'astro kemi'} />
      <div style={{ color: 'white', zIndex: 1 }}>
        {'페이지를 찾을 수 없습니다'}
      </div>
      <Button
        style={{
          width: '300px',
          marginTop: '30px',
          background: 'white',
          color: 'black',
          border: 'none',
        }}
        type={'primary'}
        htmlType={'submit'}
        block
        onClick={() => {
          router.push(ROUTE.home);
        }}
      >
        {'홈으로 이동하기'}
      </Button>
    </div>
  );
};

export default PageNotFoundPage;
